import React from "react";
import Link from "gatsby-link";
import Layout from "../layouts/sk";
import "./index.css";
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";
import 'react-animated-slider/build/horizontal.css';
import novapolhora from "../static/images/sbinvest-banner.png"
import factory from '../static/images/factory.svg'
import truck from '../static/images/truck.svg'
import MapWithAnOverlayView from "../components/OverlayMap";
import "../components/OverlayMap.css";
import SEO from "../components/seo.js";
import { graphql } from "gatsby"
import downloadFile from '../static/images/np-plan2.pdf' 


class Parcels extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      value: "S2B", 
      hideNav : false, 
      onSelect : "nic" , 
      opened : false, 
      setModal : false, 
      boxOne: false,
      boxTwo: false,
      boxThree: false,
    }

    this.handleChange = this.handleChange.bind(this);
    this.toggleBox = this.toggleBox.bind(this);
    this.boxOne = this.boxOne.bind(this);
    this.boxTwo = this.boxTwo.bind(this);
    this.boxThree = this.boxThree.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }


	toggleBox() {
		const { opened } = this.state;
		this.setState({
			opened: !opened,
		});
  }
  
  boxOne() {
		const { boxOne } = this.state;
		this.setState({
			boxOne: !boxOne,
    });
  }

  boxTwo() {
		const { boxTwo } = this.state;
		this.setState({
			boxTwo: !boxTwo,
		});
  }

  boxThree() {
		const { boxThree } = this.state;
		this.setState({
			boxThree: !boxThree,
		});
	}


  render() {
    return (
      <div>
            <Layout location={this.props.location}>
    <div>
      <Container fluid className="featured-photo">
        <img src={novapolhora} alt="nova polhora logo" className="" width="100%"/>
      </Container>
      <Container fluid className="title-npi pt-5 pb-5">
        <h3 className="text-uppercase">MODERNÝ LOGISTICKO-PRIEMYSELNÝ PARK </h3>
        <h1><span  className="title-title text-uppercase">LPP Šarišské Bohdanovce</span></h1>
        <h4>Ponúka možnosti na prenájom skladových priestorov ale aj na kúpu pozemku pre vlastnú výstavbu.<br/>
        Nachádza sa v severo-južnom európskom koridore (Poľsko-Grécko)</h4>
      </Container>
      <Container fluid>
        <Row>
          <Col md={8} className="features-beg pt-5 pb-5">
            <Row className="">
              <Col md={6}>
                <div className="features-beg-title container-basic">
                    <div className="text-center  mb-3">
                        <img src={ truck } className="" alt="truck" height="130"/>
                    </div>
                    <h5 className="text-uppercase">Skvelá poloha</h5>
                    <ul>
                        <li>na výjazde z diaľnice D1 </li>
                        <li>30 min z letiska Košice</li>
                        <li>20 min z centra Košíc a Prešova</li>
                        <li>s viac ako 500 000 obyvateľmi</li>
                        <li>ľahko dostupné verejnou dopravou </li>
                    </ul>
                </div>
              </Col>
              <Col md={6}>
                <div className="features-beg-title container-basic">
                    <div className="text-center mb-3">
                        <img src={ factory } className="" alt="factory" height="130"/>
                    </div>
                    <h5 className="text-uppercase">Skvelý potenciál</h5>
                    <ul>
                        <li>možnosť vybudovať 50.000 m<sup>2</sup> skladovej plochy</li>
                        <li>možnosť predaja pozemku na&nbsp;výstavbu konečnému užívateľovi</li>
                    </ul>
                </div>
              </Col>
            </Row>
            <div className="button-div">
            <Link to="/lokalita/"><Button outline className="button-basic primary button-margin" >Pozri dostupnosť</Button></Link>
            <Link to="/kontakt/">  <Button outline className="button-basic secondary button-margin" >Kontaktujte nás</Button></Link>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="features-container">
        <h2 className="features-title">O projekte</h2>
        <div>
          <MapWithAnOverlayView
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBOh1wAZLx_f2riCJadaLxEpjUqkGX_Gy8&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `600px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            onSelect={(parcel) => this.setState({onSelect: parcel})}
            location={this.props.location}
          />
        </div>
        <div className="button-div">
        <Link to="/lokalita/"> <Button outline className="button-basic primary-invert button-margin" >Pozri všetky výhody</Button></Link>
        <a href={downloadFile} download>{` `} <Button outline className="button-basic secondary button-margin" >Stiahnúť plán</Button></a>
        </div>
      </Container>
      <Container fluid className="container-utilization pt-5 pb-5">
        <Row>
          <Col md={4} className="col-utilization">
            <div className="content-utilization">
              <h3>O koncepte</h3>
              <p className={this.state.boxOne ? '' : 'content-utilization-paragraph text-justify'} >
              Logisticko-priemyselný park sa nachádza medzi obcou a diaľnicou, pozostáva zo stavieb, 
              ktoré vytvára areál logistiky, nezávadnej výroby ale aj obchodu a služieb. Stavba je 
              projektovaná na sever od kruhovej križovatky.  Park LPP Šarišské Bohdanovce 
              je kruhovou križovatkou napojený na diaľničný privádzač a na cestu 3325.

              </p>
              <Button outline className="button-basic primary" onClick={this.boxOne} >zobraziť{this.state.boxOne ? ' menej' : ' viac'} </Button>
            </div>
          </Col>
          <Col md={4} className="col-utilization">
            <div className="content-utilization">
              <h3>Infraštruktúra</h3>
              <p className={this.state.boxTwo ? '' : 'content-utilization-paragraph'}>
              Územie areálu S8 bude pripojené cez potok Balku mostom, ktorý je vo výstavbe. Stavba bude skoladovaná 9/2024. Prípojné body na elektrickú prípojku a prípojky pitnej vody, kanalizácie a intetnetu.
              Zberná komunikácia, umožňuje jednoduchý prístup ku kruhovej križovatke ceste 3325 a diaľničnému privádzaču. 
              </p>
              <Button outline className="button-basic primary" onClick={this.boxTwo} >zobraziť{this.state.boxTwo ? ' menej' : ' viac'} </Button>
            </div>
          </Col>
          <Col md={4} className="col-utilization">
            <div className="content-utilization">
              <h3>Územný plán</h3>
              <p className={this.state.boxThree ? '' : 'content-utilization-paragraph'}>
              Územný plán obce Šarišské Bohdanovce bol schválený uznesením obecného zastupiteľstva 
              Š.Bohdanovce č.XI.191/2018 dňa 08.11.2018. A záväzná časť sa nachádza vo VZN č.3/2018. 
              
              Územné rozhodnutie na areál S8 č. NP-118/2023 z 30.05.2023 je právoplatné od 03.07.2023.

              </p>
              <Button outline className="button-basic primary" onClick={this.boxThree} >zobraziť{this.state.boxThree ? ' menej' : ' viac'} </Button>
             
            </div>
          </Col>
        </Row>
      </Container> 
    </div>
    <SEO title="O projekte" />
  </Layout>
      </div>
    );
  }
}
export default Parcels;


export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        languages {
        defaultLangKey
        langs
        }
      }
    }
  }
`
